<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-04-10 11:37:32
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-06-11 15:54:55
 * @FilePath: \envision-webe:\sirun\performance-appraisal\src\components\HelloWorld.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-layout id="components-layout-demo-top" class="layout">
    <!-- 上部导航栏 -->
    <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
      <img
        class="logo"
        src="https://res-cim.dingding.sirun.net/logo_bai.png"
        alt="Logo"
      />
      <a-menu
        :selectedKeys="[current]"
        theme="dark"
        mode="horizontal"
        @select="handleMenuSelect"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="table"> 绩效考核表</a-menu-item>
        <a-menu-item key="myKPI"> 我的绩效</a-menu-item>
        <a-menu-item v-if="isRating" key="rating"> 绩效评分</a-menu-item>
        <a-menu-item v-if="isApproval" key="approvalKPI"> 绩效核准</a-menu-item>
        <a-menu-item v-if="isPerManager" key="perManagement"> 绩效管理</a-menu-item>
      </a-menu>
    </a-layout-header>

    <!-- 下部内容区域 -->
    <a-layout-content style="background: #fff; min-height: calc(100vh - 64px)">
      <KPITable
        v-if="current === 'table'"
        :globalAccessToken="globalAccessToken"
      />
      <MyKPI
        v-if="current === 'myKPI'"
        :globalAccessToken="globalAccessToken"
      />
      <KPIRating
        v-if="current === 'rating'"
        :globalAccessToken="globalAccessToken"
      />
      <ApprovalKPI
        v-if="current === 'approvalKPI'"
        :globalAccessToken="globalAccessToken"
      />
      <PerManagement
        v-if="current === 'perManagement'"
        :globalAccessToken="globalAccessToken"
      />
    </a-layout-content>
  </a-layout>
</template>
<script>
import axios from "axios";
import KPITable from "./KPITable.vue";
const baseUrl = "https://cim-test.dingding.sirun.net/";
import MyKPI from "./MyKPI.vue";
import ApprovalKPI from "./ApprovalKPI.vue";
import KPIRating from "./KPIRating.vue";
import PerManagement from "./PerManagement.vue";
import * as dd from "dingtalk-jsapi";
const ACCESS_TOKEN_URL = "https://cim-test.dingding.sirun.net/get_access_token";
const CORP_ID = "ding22edd3ac4f8e7fa8a1320dcb25e91351";
const USER_INFO_URL =
  "https://cim-test.dingding.sirun.net/get_dingtalk_user_info";
export default {
  components: {
    KPITable,
    MyKPI,
    PerManagement,
    ApprovalKPI,
    KPIRating,
  },
  data() {
    return {
      current: "table",
      globalAccessToken: "",
      //是否有绩效评分权限
      isRating: false,
      //是否有核准权限
      isApproval: false,
      //是否有绩效管理权限
      isPerManager: false,
      //具有核准的人
      approvals: [
      ],
      //具有绩效管理的人
      perManagements: [],
      item: 1,
    };
  },
  mounted() {
    this.getPlatform();
    this.getAccessToken();
  },
  methods: {
    //获取权限校验
    async getAccessToken() {
      try {
        const response = await axios.get(ACCESS_TOKEN_URL);
        this.globalAccessToken = response.data.access_token;
        this.init();
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    async init() {
      dd.getAuthCode({
        corpId: CORP_ID,
        success: async (res) => {
          await this.getUserInfo(res.code);
        },
        fail: (err) => {
          console.error("getAuthCode 失败:", err);
        },  
        complete: () => {},
      });
    },
    async getUserInfo(code) {
      const url = `${USER_INFO_URL}?access_token=${this.globalAccessToken}&user_code=${code}`;
      try {
        const response = await axios.get(url);
        console.info("basicpage-getUserInfo-response", response);
        const data = await response.data;
        console.info("datanew", data);
        if (response.status !== 200) {
          throw new Error(`获取免登录用户数据失败: ${response.status}`);
        }
        const { name } = data.result;
        this.iskey(name);
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    // 环境判断
    getPlatform() {
      if (dd && dd.env) {
        if (
          dd.env.platform.toLowerCase() === "ios" ||
          dd.env.platform === "android"
        ) {
          window.location.href = "https://res-cim.dingding.sirun.net/ban.html";
        } else {
          console.log("当前不在钉钉的手机环境中");
        }
      }
    },
    handleMenuSelect({ key }) {
      this.current = key;
      console.log("this.current", this.current);
    },
    async iskey(name) {
      console.log("name", name);
      try {
        const res = await axios.get(baseUrl + "access_approval_users");
        const res1 = await axios.get(baseUrl + "access_manage_users");
        console.log("iskey->res1.data", res1.data);
        this.approvals = res.data
        if (this.approvals.includes(name)) {
          this.isApproval = true;
        }
        this.perManagements = res1.data
        if (this.perManagements.includes(name)) {
          this.isPerManager = true;
        }
        axios
          .get(baseUrl + `user_info_by_assessor_count/${name}`)
          .then((response) => {
            // 处理响应数据
            console.log("basicpage-getUserInfo-response-response", response);
            if (response.status !== 200) {
              throw new Error(`获取免登录用户数据失败: ${response.status}`);
            }
            if (response.data !== 0) {
              this.isRating = true;
            }
          });
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>
<style scoped>
#components-layout-demo-top .logo {
  width: 150px;
  height: auto;
  margin-top: 0.8%;
  margin-left: -1%;
  float: left;
}
</style>
