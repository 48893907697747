<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-04-10 14:56:13
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-07 16:56:36
 * @FilePath: \envision-webe:\sirun\performance-appraisal\src\components\KPIRating.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    id="performance-rating"
    class="div-body active"
    style="padding-top: 80px; padding-left: 20px; padding-right: 20px"
  >
    <div class="layui-form">
      <h2>绩效管理</h2>
      <form id="performanceRatingForm" class="layui-form">
        <div class="layui-form-item">
          <label class="layui-form-label" style="width: 120px"
            >选择考评人:</label
          >
          <div class="layui-input-block">
            <a-select
              placeholder="请选择考评人"
              @change="getTable"
              v-model="currentAppraiser"
              allow-clear
              show-search
              :filter-option="filterOption"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in appraiserArray"
                :key="item.userid"
                :value="item.userid"
                >{{ item.username }}</a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label" style="width: 120px"
            >选择考评月:</label
          >
          <div class="layui-input-block">
            <a-select
              v-model="selecMonth"
              placeholder="选择考评月"
              @change="getTable"
              style="width: 60%"
            >
              <a-select-option
                v-for="month in evMonths"
                :key="month.value"
                :value="month.value"
                >{{ month.text }}</a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="layui-row">
          <div class="layui-col-sm2 layui-col-md-offset10">
            <a-button type="primary" @click="exportKPI" class="my-button">
              导出本月绩效
            </a-button>
          </div>
        </div>
        <table id="assessorPerformanceTable" class="layui-table">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th style="width: 10%">指标类别</th>
              <th style="width: 30%">指标名称</th>
              <th style="width: 30%">计算/评分规则</th>
              <th style="width: 10%">考核分数</th>
              <th style="width: 20%">考核得分</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in KPIRows" :key="index">
              <td>
                {{ row.category }}
              </td>
              <td>
                {{ row.description }}
              </td>
              <td>
                {{ row.rule }}
              </td>
              <td>
                {{ row.examine_score }}
              </td>
              <td>
                {{ row.score }}
              </td>
            </tr>
            <tr v-if="approvalscore !== 0">
              <td colspan="4" style="text-align: right">核准评分:</td>
              <td>
                {{ approvalscore }}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>

    <div
      id="rating-manage"
      class="div-body"
      style="padding-top: 80px; padding-left: 20px; padding-right: 20px"
    >
      <div class="layui-form">
        <h2>绩效管理</h2>
        <form id="ratingManageForm" class="layui-form"></form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const baseUrl = "https://cim-test.dingding.sirun.net/";
const CORP_ID = "ding22edd3ac4f8e7fa8a1320dcb25e91351";
import * as dd from "dingtalk-jsapi";
export default {
  props: {
    globalAccessToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //考评人
      appraiserArray: [],
      //当前考核人
      currentAppraiser: undefined,
      rows: [],
      KPIRows: [],
      //考评月
      evMonths: [],
      //选中月份
      selecMonth: undefined,
      approvalscore: 0,
      //当前考核月
      currentMonth: "",
    };
  },
  watch: {
    globalAccessToken: {
      handler(newVal) {
        if (newVal) {
          this.init();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.globalAccessToken !== "") {
      this.init();
    }
  },
  methods: {
    async init() {
      this.initializeMonths();
      dd.getAuthCode({
        corpId: CORP_ID,
        success: async () => {
          await this.fetchEmployees();
        },
        fail: (err) => {
          console.error("getAuthCode 失败:", err);
        },
        complete: () => {},
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(baseUrl + `get_all_users`);
        if (response.status !== 200) {
          throw new Error("网络异常4");
        }
        const data = response.data;
        this.appraiserArray = data;
        console.log("测试输出所有员工 ->", data);
      } catch (error) {
        console.error("未能获取员工列表:", error);
      }
    },
    initializeMonths() {
      this.evMonths = [];
      for (let i = 1; i <= 12; i++) {
        const formattedMonth = i < 10 ? "0" + i : "" + i;
        this.evMonths.push({
          text: formattedMonth + "月",
          value: formattedMonth + "月",
        });
      }
      const currentDate = new Date();
      let month = currentDate.getMonth();

      // 如果当前日期在3号之前，则仍然算作上一个月
      if (currentDate.getDate() <= 3) {
        // 如果当前月是1月，则需要回到上一年的12月
        if (month === 0) {
          month = 11; // 12月
        } else {
          month--; // 月份减1
        }
      }
      const formattedMonth =
        month + 1 < 10 ? "0" + (month + 1) : "" + (month + 1);
      this.selecMonth = formattedMonth + "月";
    },
    getTable() {
      if (this.currentAppraiser && this.selecMonth) {
        const currentDate = new Date();
        let year = currentDate.getFullYear();
        const formattedNextMonth = `${year}年${this.selecMonth}`;
        this.fetchAndFillMyPerformanceTable(
          this.currentAppraiser,
          formattedNextMonth
        );
      }
    },
    async fetchAndFillMyPerformanceTable(userid, assessmentPeriod) {
      try {
        const url =
          baseUrl +
          `get_performance_status_data?userid=${userid}&assessment_period=${assessmentPeriod}`;
        const utl2 =
          baseUrl +
          `get_approvalscore_data?userid=${userid}&assessment_period=${assessmentPeriod}`;
        const response = await axios.get(url);
        const res = await axios.get(utl2);
        console.log("fetchAndFillMyPerformanceTable", response.data);
        if (res.status !== 200) {
          throw new Error("网络异常");
        }
        this.approvalscore = res.data.approvalscore;
        if (response.status !== 200) {
          throw new Error("网络异常");
        }
        if (!response.data.message) {
          this.KPIRows = response.data;
          console.log("fetchAndFillMyPerformanceTable", response.data);
        } else {
          this.KPIRows = [];
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async exportKPI() {
      const currentDate = new Date();
      let year = currentDate.getFullYear();
      const formattedNextMonth = `${year}年${this.selecMonth}`;
      const url =
        baseUrl +
        `save_performance_to_excel?assessment_period=${formattedNextMonth}`;
      try {
        const response = await axios.get(url);
        const res = response.data;
        if (res.message) {
          this.$message.info(res.message);
          return;
        }
        console.log("save_performance_to_excel", res);
        const downloadLink = document.createElement("a"); // 创建一个 <a> 元素
        downloadLink.href =
          "https://cim-test.dingding.sirun.net//excel/get/" + res; // 设置 <a> 元素的 href 属性为 Blob URL
        downloadLink.download = formattedNextMonth + "绩效.xlsx"; // 设置下载文件的文件名
        downloadLink.click();
      } catch (error) {
        console.error("下载失败", error);
        // 处理下载失败的情况
      }
    },
  },
};
</script>

<style>
.my-button {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  background-color: #1e9fff !important;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
.my-button-disabled {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
}
.my-per-button {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  border-color: #16baaa !important;
  color: #16baaa !important;
  background: 0 0;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
</style>
